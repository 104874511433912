import React from "react"
import ESTextLogo from "../Resources/ESTextLogo.png"
import TwitterLogo from "../Resources/twitter.png"
import { NavLink } from "react-router-dom"
import { useState } from "react"
import Burger from "../Resources/Buttons/burger.png"

export default function Header() {
  const [incubatorSelected, setIncubatorSelected] = useState(false)
  const clickIncubator = () => setIncubatorSelected(!incubatorSelected)
  const [burgerActive, setBurgerActive] = useState(false)
  const [overlayStatus, setOverlayStatus] = useState(false)

  const burgerClick = () => {
    setBurgerActive(!burgerActive)
    setOverlayStatus(true)
  }

  const burgerInactivate = () => {
    setBurgerActive(false)
    setOverlayStatus(false)
  }

  const activeBurgerStyle =
    "w-24 -translate-x-16 h-40 absolute mt-2 border-2 border-eslight bg-esdark z-50 text-right rounded-md"
  const inactiveBurgerStyle = "hidden"

  React.useEffect(() => {
    function handleResize() {
      if (window.innerWidth >= 1024) {
        setBurgerActive(false)
      }
    }

    window.addEventListener("resize", handleResize)
  })

  const overlayClick = () => {
    setOverlayStatus(false)
    setBurgerActive(false)
  }

  const overlayActive = "w-full h-full absolute left-0 bottom-0"
  const overlayInactive = "hidden w-full h-full absolute left-0 bottom-0"

  return (
    <div className="h-24 w-screen flex justify-between items-center px-6 bg-esdark">
      <div id="left-container" className="w-1/2">
        <NavLink to="/">
          <img
            onClick={burgerInactivate}
            src={ESTextLogo}
            alt=""
            className="w-40 lg:w-80 h-8 lg:h-16"
          />
        </NavLink>
      </div>
      <div
        id="right-container"
        className="w-1/2 flex justify-end items-center lg:w-2/5 lg:block h-full"
      >
        <div
          id="overlay for click"
          onClick={overlayClick}
          className={overlayStatus ? overlayActive : overlayInactive}
        ></div>
        <div className="z-40 hover:cursor-pointer">
          <img
            onClick={burgerClick}
            className="w-8 h-8 z-30 lg:hidden"
            src={Burger}
            alt=""
          />
          <div
            onBlur={burgerInactivate}
            className={burgerActive ? activeBurgerStyle : inactiveBurgerStyle}
          >
            <ul className="flex flex-col h-full justify-around mr-2">
              <NavLink to="/blog">
                <li
                  onClick={burgerInactivate}
                  className="transition-all duration-200 hover:text-eslight hover:cursor-pointer"
                >
                  Blog
                </li>
              </NavLink>
              <NavLink to="/team">
                <li
                  onClick={burgerInactivate}
                  className="transition-all duration-200 hover:text-eslight hover:cursor-pointer"
                >
                  Team
                </li>
              </NavLink>
              <NavLink to="/portfolio">
                <li
                  onClick={burgerInactivate}
                  className="transition-all duration-200 hover:text-eslight hover:cursor-pointer"
                >
                  Portfolio
                </li>
              </NavLink>
              <NavLink to="/ventures">
                <li
                  onClick={burgerInactivate}
                  className="transition-all duration-200 hover:text-eslight hover:cursor-pointer"
                >
                  Ventures
                </li>
              </NavLink>
              <NavLink to="/tools">
                <li
                  onClick={burgerInactivate}
                  className="transition-all duration-200 hover:text-eslight hover:cursor-pointer"
                >
                  Tools
                </li>
              </NavLink>
            </ul>
          </div>
        </div>
        <ul className="hidden lg:flex justify-between text-sm lg:text-xl xl:text-2xl items-center h-full">
          <NavLink to="/blog">
            <li className="transition-all duration-200 hover:text-eslight hover:cursor-pointer">
              Blog
            </li>
          </NavLink>
          <NavLink to="/team">
            <li className="transition-all duration-200 hover:text-eslight hover:cursor-pointer">
              Team
            </li>
          </NavLink>
          <NavLink to="/portfolio">
            <li className="transition-all duration-200 hover:text-eslight hover:cursor-pointer">
              Portfolio
            </li>
          </NavLink>
          <div /* onMouseEnter={clickIncubator} */>
            <NavLink to="/ventures">
              {" "}
              <li className="transition-all duration-200 hover:text-eslight hover:cursor-pointer">
                Ventures
              </li>
            </NavLink>
            {/* <div
            onMouseLeave={clickIncubator} 
              className={incubatorSelected ? activeButton : inactiveButton}
            >
              <ul className="px-2 ">
                <li className="text-navtext text-lg py-0.5 hover:cursor-pointer hover:text-eslight">
                  Defi
                </li>
                <li className="text-navtext text-lg py-0.5 hover:cursor-pointer hover:text-eslight">
                  NFTs
                </li>
                <li className="text-navtext text-lg py-0.5 hover:cursor-pointer hover:text-eslight">
                  GameFi
                </li>
              </ul>
  </div> */}
          </div>
          <NavLink to="/tools">
            <li className="transition-all duration-200 hover:text-eslight hover:cursor-pointer">
              Tools
            </li>
          </NavLink>
          <li>
            <a href="https://twitter.com/EdgeSimps" target="_blank">
              <img
                src={TwitterLogo}
                className="w-7 opacity-20 cursor-pointer"
                alt=""
              />
            </a>
          </li>
        </ul>
      </div>
    </div>
  )
}
