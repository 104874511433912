import React from "react"

export default function Tools() {
  return (
    <div className="w-screen h-screen flex items-center justify-center  px-6">
      <h1 className="text-eslight text-3xl lg:text-7xl font-bold ">
        Coming soon...
      </h1>
    </div>
  )
}
