import React from "react"
import arrow from "../Resources/Arrow.png"
import greenhalf from "../Resources/greenhalfarrow.png"
import redhalf from "../Resources/redhalfarrow.png"
import { Link } from "react-router-dom"

export default function Blog() {
  const greenName = "mt-2 text-eslight text-sm lg:text-base font-semibold"
  const name = "mt-2 text-sm lg:text-base font-semibold"

  return (
    <div className="w-screen h-screen flex flex-col px-12 md:px-16">
      <h1 className="text-2xl lg:text-5xl mt-4 mb-8 lg:mb-12 font-bold">
        EdgeSimp Writings
      </h1>
      <div className="h-full flex flex-col justify-start pl-0 lg:pl-8">
        {/* Item 2. In it for the Tech*/}
        <div className="flex items-start lg:mb-20">
          <div className="h-20 flex flex-col lg:justify-between">
            <Link to="/blog/in-it-for-the-tech">
              {" "}
              <h1 className="text-md lg:text-4xl font-bold">
                In it for The Tech
              </h1>
            </Link>
            <Link to="/blog/in-it-for-the-tech">
              <h3 className="hidden md:block mt-2 text-sm md:text-base lg:text-xl">
                Thought-provoking projects that more people should be talking
                about
              </h3>
            </Link>
            <h2 className={name}>
              <a href="https://twitter.com/nosleepjon" target="_blank">
                @nosleepjon
              </a>{" "}
              - 06/6/22
            </h2>
          </div>
          <Link to="/blog/in-it-for-the-tech">
            <img
              src={redhalf}
              className="translate-y-2 ml-6 lg:ml-12 w-4 lg:w-6 cursor-pointer"
              alt=""
            />
          </Link>
        </div>
        {/* Item 1. Why your public roadmap... */}
        <div className="flex items-start">
          <div className="h-20 flex flex-col justify-between">
            <Link to="/blog/why-your-public-roadmap-for-your-jpeg-project-is-stupid">
              {" "}
              <h1 className="text-md lg:text-4xl font-bold">
                Why Your Public Roadmap For Your Jpeg Project Is Stupid
              </h1>
            </Link>
            <Link to="/blog/why-your-public-roadmap-for-your-jpeg-project-is-stupid">
              <h3 className="hidden md:block mt-2 text-sm md:text-base lg:text-xl">
                A new framework for planning in public & communicating to your
                NFT holders instead of public roadmaps
              </h3>
            </Link>
            <h2 className={name}>
              <a href="https://twitter.com/WhosDaveStanton" target="_blank">
                @WhosDaveStanton{" "}
              </a>{" "}
              - 05/4/22
            </h2>
          </div>
          <Link to="/blog/why-your-public-roadmap-for-your-jpeg-project-is-stupid">
            <img
              src={greenhalf}
              className="translate-y-2 ml-6 lg:ml-12 w-4 lg:w-6 cursor-pointer"
              alt=""
            />
          </Link>
        </div>
      </div>
    </div>
  )
}
