import React from "react"
import Dave1 from "../../Resources/Blogs/Dave5_4_22/Dave5_4_22_1.jpeg"
import grayarrow from "../../Resources/grayarrow.png"
import { Link } from "react-router-dom"
import { list } from "postcss"

export default function Dave5_4_22() {
  const titleClass = "text-2xl lg:text-5xl mt-4"
  const nameClass = "text-md lg:text-xl mt-4 text-eslight"
  const paragraphClass = "mt-4 lg:mt-8 text-sm lg:text-xl"
  const subheadingClass =
    "mt-4 lg:mt-8 text-lg lg:text-2xl underline font-extrabold "
  const firstListItemClass = "text-sm lg:text-xl"
  const listItemClass = "text-sm lg:text-xl mt-4"
  const listClass = "mt-4 list-disc translate-x-16 w-5/6 xx"

  return (
    <div className="flex flex-col items-center bg-esdark flex-1">
      <div className="w-4/5 lg:w-3/5">
        <h1 className={titleClass}>
          Why Your Public Roadmap For Your Jpeg Project Is Stupid
        </h1>
        <h1 className={nameClass}>@WhosDaveStanton - 5/4/2022</h1>
      </div>
      <div className="flex flex-col text-xl w-4/5 lg:w-3/5 text-block">
        <h1 className={paragraphClass}>
          The goal of this new model is to eliminate the problems with public
          roadmaps while giving a framework for founders to use that gives the
          community realistic expectations, keeps them engaged and up to date on
          the project progress but isn’t too burdensome on the team.
        </h1>
        <h1 className={subheadingClass}>Public Roadmaps:</h1>
        <img src={Dave1} alt="" className="w-full mt-4" />
        <h1 className={paragraphClass}>
          Anyone who’s spent a minute in crypto has seen a public roadmap. It’s
          the plan for the next four quarters for a specific project. These are
          good because they illustrate the goals and milestones of the project.
          They also create a lot of hype which can be good for selling your NFT
          but also can lead to an overexcited community that may have
          unrealistic expectations.
        </h1>
        <h1 className={paragraphClass}>
          The key factor in future success here is whether a team creates a
          realistic plan for moving forward or overpromises merely to create
          hype and then either abandons the project (rugs it) or just prays they
          can meet their grandiose goals. The former is definitely a better
          strategy than the later for anyone who’s not a grifter.
        </h1>
        <h1 className={subheadingClass}>Why They Suck</h1>
        <h1 className={paragraphClass}>
          There’s a lot of ways this can backfire so I’ll just list a few.
        </h1>
        <ul className={listClass}>
          <li className={firstListItemClass}>
            Creates constant questions from the public about the status of every
            point
          </li>
          <li className={listItemClass}>
            Creates stress on the team/community managers to answer these
            questions and communicate progress constantly
          </li>
          <li className={listItemClass}>
            If you miss or change a milestone it’s glaring on your roadmap that
            usually is on your main website and creates bad optics
          </li>
          <li className={listItemClass}>
            If you need to adjust the roadmap (like every startup ever has to
            all the time) you create a ton of bad PR and additional distractions
            for the team as people cry about it on twitter and in your discord
            even if you give valid reasons as to why
          </li>
          <li className={listItemClass}>And on and on</li>
        </ul>
        <h1 className={subheadingClass}>A Proposed New Framework</h1>
        <h1 className={paragraphClass}>
          Write a litepaper when you start to communicate the vision, goals, and
          general plan of how to execute. Give rough timelines but it doesn’t
          need to be a specific list of each feature you’re doing for each
          quarter.
        </h1>
        <h1 className={paragraphClass}>
          In my opinion, this also helps frame the starting point and doesn’t
          make it seem like you should be beholden to everything in your
          litepaper. This is unlike public roadmaps where a decision you made 9
          months ago when you made this arbitrary roadmap can come back to bite
          you in the ass because certain project dynamics or overall market
          dynamics have changed and you need to alter the plan. Anyone with a
          brain knows that as time goes on your project will inevitably adjust
          as you get feedback from the market so this litepaper really is just
          your initial vision and plan.
        </h1>
        <h1 className="mt-8 italic">Then do the following: </h1>
        <ul className={listClass}>
          <li className={firstListItemClass}>
            Put out quarterly roadmaps limited to only the upcoming quarter and
            do this via a community call you treat like a mini board meeting
          </li>
          <li className={listItemClass}>
            This roadmap is just for the next quarter not the year
          </li>
          <li className={listItemClass}>
            Make it high level; we plan to do X, Y, and Z features this upcoming
            quarter
          </li>
          <li className={listItemClass}>
            Review what you did last quarter on the call first
          </li>
          <li className={listItemClass}>
            If you missed or changed any deliverables on the last quarter
            roadmap, just say why.
          </li>
          <li className="mt-4 text-xs lg:text-xl translate-x-16 w-5/6 list-outside">
            We did A & B features but missed/changed C because; QA took longer
            than expected, we pivoted feature C due to market feedback mid
            quarter, 1 key artist got sick… or whatever the reason is
          </li>
          <li className={listItemClass}>
            Then put out your next quarter roadmap; We plan to finish C and do
            D,E,F features. Release it in the Discord so it’s for the community
            and people seeking it out. It doesn’t need to be front and center on
            your website. That’s clearly someone who is using it for hype rather
            than accurate planning & effective communication to stakeholders
          </li>
          <li className={listItemClass}>
            Next remind people of some key metrics or goals for end of year or
            some time a few quarters down the road - this is the medium term
            stuff for your project
          </li>
          <li className={listItemClass}>
            After that, remind people that all this stuff in the upcoming
            quarter, and the medium term goals is to work towards your north
            star vision - this is the long term stuff
          </li>
          <li className={listItemClass}>Take Q&A in the discord</li>
          <li className={listItemClass}>
            Put the docs in a roadmap update channel with a recording of the
            meeting
          </li>
          <li className={listItemClass}>
            Sign off and tell everyone, see you next quarter
          </li>
        </ul>
        <h1 className={paragraphClass}>
          After that, anyone who asks in the discord, “what’s going on?” or
          anything about the status of development should just be directed there
          to listen to the meeting and see the current quarter roadmap.
        </h1>{" "}
        <h1 className={paragraphClass}>
          Additionally, any project with investors that isn’t bootstrapped has
          to essentially do this for the board anyways so you can just repurpose
          material and take out anything confidential or sensitive to make it
          even easier on the team.
        </h1>{" "}
        <h1 className={subheadingClass}>TLDR;</h1>
        <h1 className={paragraphClass}>
          When roadmaps are realistic from the get go, the team communicates in
          a consistent clear manner to the community about their decisions,
          teams treat NFT owners like they would investors, and you give
          measured quarter by quarter milestones rather than year long overhyped
          roadmaps, you will reduce community induced headaches, see much more
          success as a team and garner a better relationship with your holders.
        </h1>{" "}
        <div className="w-full mt-10 flex items-center justify-end">
          <Link to="/blog">
            <div className="flex items-center">
              <img className="w-4 h-4 mr-2" src={grayarrow} alt="" />
              <h1 className="text-base">back to blogs</h1>
            </div>
          </Link>
        </div>
        <div className="h-20"> </div>
      </div>
    </div>
  )
}
