import React from "react"
import Dave1 from "../../Resources/Blogs/Dave5_4_22/Dave5_4_22_1.jpeg"
import grayarrow from "../../Resources/grayarrow.png"
import { Link } from "react-router-dom"

export default function Dave5_4_22() {
  const titleClass = "text-2xl lg:text-5xl mt-4"
  const nameClass = "text-md lg:text-xl mt-4 text-eslight"
  const paragraphClass = "mt-4 lg:mt-8 text-sm lg:text-xl"
  const subheadingClass =
    "mt-4 lg:mt-8 text-lg lg:text-2xl underline font-extrabold "
  const firstListItemClass = "text-sm lg:text-xl"
  const listItemClass = "text-sm lg:text-xl mt-4"
  const listClass = "mt-4 list-disc translate-x-16 w-5/6 xx"

  return (
    <div className="flex flex-col items-center bg-esdark flex-1 ">
      <div className="w-4/5 lg:w-3/5">
        <h1 className={titleClass}>In it for The Tech</h1>
        <h1 className={nameClass}>@nosleepjon - 6/6/2022</h1>
      </div>
      <div className="flex flex-col text-xl w-4/5 lg:w-3/5 text-block">
        <h1 className={paragraphClass}>
          It’s a bear market. You’re down bad financially, mentally,
          emotionally, physically, spiritually. It’s ok, we’ve all been there,
          etc. etc. etc. Look, I’m not your therapist. You’re going to have to
          figure that shit out yourself. What I can offer are a random syntax of
          words that may or more not take your mind off your current losses and
          focus on what’s really important: the tech behind your future losses.
        </h1>
        <h1 className={paragraphClass}>
          So instead of you making more unsound financial decisions, whining,
          and ruining my Twitter timeline, let’s talk some tech.
        </h1>
        <h1 className={subheadingClass}>Obligatory disclosures: </h1>
        <ul className={listClass}>
          <li className={firstListItemClass}>
            I have no positions or financial exposure to any of these protocols
            (as of writing)
          </li>
          <li className={listItemClass}>I was not paid to write any of this</li>
          <li className={listItemClass}>
            I have no idea or projection on how any prospective tokens will
            perform
          </li>
          <li className={listItemClass}>
            I am not telling you to buy anything, ever
          </li>
          <li className={listItemClass}>I’m in it for the tech (for now)</li>
          <li className={listItemClass}>
            My incentive for writing this is to claim clout if they do well and
            to sound smarter than I actually am
          </li>
          <li className={listItemClass}>
            Most of the info is pulled straight from the project docs/mediums
            and I’m just oversimplifying/interpreting it here. Remember, my main
            goal is to sound smart, not produce any actual original content. Go
            google the project docs if you don’t like my interpretations.
          </li>
        </ul>
        <h1 className={paragraphClass}>
          Ok now that all of that’s clear, here are some projects I find
          thought-provoking and innovative that deserve more attention in this
          noisy bear market:
        </h1>
        <h1 className={subheadingClass}>CrocSwap</h1>
        <h1 className={paragraphClass}>
          CrocSwap is a decentralized exchange consolidated in a single smart
          contract that supports both concentrated and ambient liquidity. The
          protocol is a major step forward in DEX development and will likely be
          the most efficient AMM on the market when it goes live. It achieves
          the efficiency through multiple compounding design choices:
        </h1>
        <ul className={listClass}>
          <li className={firstListItemClass}>
            Single smart contract. All the liquidity pools are consolidated at
            one smart contract address. Unlike other DEX’s which have to hop
            between multiple pools and smart contracts to execute swaps,
            CrocSwap can execute it all with the one smart contract, producing
            major gas savings for traders and users/protocols launching their
            own liquidity pools with CrocSwap.
          </li>
          <li className={listItemClass}>
            Dual liquidity model with both ambient and concentrated liquidity.
            Ambient liquidity is liquidity provided over an unlimited range
            while concentrated liquidity is liquidity provided over limited
            ranges. Think UniSwap V2 and UniSwap V3 stacked on each other. This
            enables LPs to have the optionality of having the fungible and more
            composable ambient LP tokens or choosing the concentrated liquidity
            range, all within the same smart contract, which prevents liquidity
            fragmentations as seen in Uni V2 and Uni v3.
          </li>
          <li className={listItemClass}>
            LP fees are autocompounded, so no need to waste gas on collecting
            and redepositing fees
          </li>
          <li className={listItemClass}>
            Fee tiers for liquidity pools. LPs can now customize their liquidity
            preferences based on their market views. Low fee tiers are
            advantageous for LPs in low volume/volatility conditions due to the
            tier being cheaper for the trader, so majority of volume and fees
            will be conducted here. High fee tiers are advantageous for LPs in
            high volume/volatility conditions due to the slippage minus fees
            being cheaper for the trader.
          </li>
          <li className={listItemClass}>
            JIT liquidity protection. JIT stands for Just-In-Time liquidity, a
            popular MEV tactic in concentrated liquidity pools to add extremely
            precise liquidity at the exact price of an incoming DEX swap order,
            before the swap is executed in order to collect all of the LP fees
            on the swap. JIT liquidity dulls much of the incentive to provide
            passive liquidity by extracting most of the fees from the pool.
            CrocSwap combats this with protocol and individual pool level
            options to add thresholds to restrict JIT liquidity flow.
          </li>
        </ul>
        <h1 className={paragraphClass}>
          How the complex fee tiers, dual liquidity model, and protocol
          governance plays out will be exciting to see. This might be another
          MEV playground to monitor when it goes live.{" "}
        </h1>
        <h1 className={subheadingClass}>Alkimiya</h1>
        <h1 className={paragraphClass}>
          Alkimiya is a market/protocol for blockspace structured products, or
          consensus capital markets. The hottest commodity this bull run was
          blockspace. as evidenced by the crazy ethereum gas fees, and the rise
          of alt-L1s to fill the demand for blockspace. Miners and validators
          set up shop to provide the blockspace, and in return are rewarded with
          block rewards and fees. But the miners/validators are also exposed to
          the price of the underlying asset, which introduces unpredictable
          volatility, especially in Proof of Stake networks where validators
          need to own the underlying token to validate the network and receive
          the rewards. The volatility means the block producers aren’t even
          guaranteed to make profit for the risk they take on, which prices out
          smaller miners/validators, a potential threat to the decentralization
          of the networks.{" "}
        </h1>
        <h1 className={paragraphClass}>
          Alkimiya introduces commodity swaps for hashpower and blockspace.
          Buyer pays the block producer a fixed stablecoin payment, vested over
          duration of the contract in exchange for all the validation
          rewards/fees over the same duration. This fulfills the need for block
          producers to hedge their directional exposure and lock in operational
          expenses, while giving speculators a market to speculate on blockspace
          demand.
        </h1>
        <h1 className={paragraphClass}>
          Blockspace swaps introduce a new, unique competitor to staking
          derivatives. Current staking derivatives force validators to take on
          directional exposure. Selling a staked asset locks in the price for
          future validation rewards/fees, but this also forces validators to
          choose their entries and exits with their staked asset hedges. Imagine
          being a validator and needing to be constantly updated on the market
          to time your hedges, this is suboptimal. Alkimiya allows block
          producers to focus on just block producing.
        </h1>
        <h1 className={paragraphClass}>
          That said, while it’s pretty nice in theory, in practice, swaps are
          pretty complex products that might not garner a very liquid market.
          Composability is also in question due to swaps having different
          expiries which can make it more difficult to build on. Maybe
          implementing something like{" "}
          <a
            className="underline"
            href="https://eips.ethereum.org/EIPS/eip-4626"
            target="_blank"
          >
            ERC-4626
          </a>{" "}
          for the vaults can make them more composable? We’ll see, it’s still
          early. Alkimiya currently has hashpower swaps and vaults live in v1,
          with blockspace swaps/vaults coming later.
        </h1>

        <h1 className={subheadingClass}>Nomad</h1>
        <h1 className={paragraphClass}>
          Nomad is a general messaging layer that enables smart contracts to
          communicate cross-chain. The most basic implementation that everyone
          knows about is liquidity bridging, so sending tokens cross chain. As a
          general messaging protocol, it unlocks an even broader array of
          possibilities such as cross chain lending, cross chain gaming
          implementations, and a bunch of other composable features that no one
          has even thought of. The use cases are more or less the same as its
          competitors LayerZero, Wormhole, IBC and the other general cross chain
          messaging protocols. The Bridge Wars deserves its own article tbh but
          that’s a discussion for another day.
        </h1>
        <h1 className={paragraphClass}>
          So what makes Nomad different? All of these bridges/messaging
          protocols make various tradeoffs to secure market share. You can
          debate about the nuances of this but I think for the casual observer
          the speed, cost, trust assumption trilemma framework should be enough
          to get a general idea about bridges (other factors such as liveness as
          well, but too complex for this piece). For example, if you really
          really oversimplify it (and I emphasize oversimplify, don’t crucify
          me), LayerZero makes trust-assumption tradeoffs while maximizing speed
          and keeping costs low. IBC makes cost tradeoffs while focusing on
          speed and trust-assumptions. Nothing wrong with it. Nomad makes the
          speed tradeoff while optimizing for cost and trust/security.
        </h1>
        <h1 className={paragraphClass}>
          Nomad uses an optimistic model similar to optimistic rollups, where
          off-chain Watchers have 30 minutes to dispute messages. I’m not going
          to deep dive the mechanisms because it would take an entire article in
          itself, you can read the docs here. The optimistic design guarantees
          that fraudulent messages submitted by dishonest actors are always
          known before they are processed in the 30 minute latency window, and
          that any one honest actor can challenge the fraud in that window. Or
          in other words, a 1-of-n model where any 1 out of an unlimited number
          of Watchers can challenge fraudulent messages. Nomad is probably the
          most trust-minimized cross chain messaging design I’ve seen so far. A
          caveat is that Nomad currently requires Watchers to be whitelisted,
          which isn’t perfect, but should decentralize over time.
        </h1>
        <h1 className={paragraphClass}>
          Of course, the obvious downside of Nomad is that the 30 minute latency
          is a major annoyance for users, who mostly only care about speed and
          cost these days. The increasing rate of bridge hacks should probably
          push more people to care about security and trust assumptions over
          time and Nomad has a good design to attract the more risk averse
          users. Just a guess, but I think the 30 minute window will likely be
          decreased eventually as more Watchers are onboarded and the system is
          streamlined. Also, Nomad has a partnership with Connext that kind of
          fixes the latency problem, using Connext’s fast liquidity and local
          verification to get liquidity in a few minutes instead of Nomad’s 30
          minutes, more details{" "}
          <a
            href="https://blog.connext.network/connext-has-partnered-with-nomad-e20cd8e62e31"
            target="_blank"
            className="underline"
          >
            here
          </a>
          .
        </h1>

        <h1 className={subheadingClass}>Closing thoughts</h1>
        <h1 className={paragraphClass}>
          Remember, cool design don’t mean good token. And to be crystal clear,
          I’m not calling for any of them to dominate their verticals. I just
          think they’re cool/unique designs that need to be talked about more.
          If you’re mad at me for not writing about your bags, I don’t care.
          You’re mad because your bags bad. Anyways, I might write more of these
          and make it a series if I feel like it and/or see any other cool tech.
          As always, NFA etc. etc. You can reach out to me on{" "}
          <a
            href="www.twitter/nosleepjon"
            target="_blank"
            className="underline"
          >
            Twitter
          </a>{" "}
          if you have any questions/feedback. Cheers.
        </h1>
        <div className="w-full mt-10 flex items-center justify-end">
          <Link to="/blog">
            <div className="flex items-center">
              <img className="w-4 h-4 mr-2" src={grayarrow} alt="" />
              <h1 className="text-base">back to blogs</h1>
            </div>
          </Link>
        </div>
        <div id="footspace" className="h-20">
          {" "}
        </div>
      </div>
    </div>
  )
}
