import React from "react"
import ESLogo from "../Resources/ESLogo.png"

export default function Home() {
  return (
    <div className="w-screen flex items-start lg:items-center px-8 lg:px-16">
      <div
        id="left-container"
        className="flex w-full lg:w-1/2 h-1/4 lg:h-1/2 flex-col justify-between lg:-translate-y-6 "
      >
        <div className="">
          <h1 className="text-4xl lg:text-6xl font-bold">The EdgeSimps</h1>

          <h2 className="text-base ml-1 w-full lg:text-3xl mt-2">
            Quant Ops. Development. Degeneracy.
          </h2>
        </div>
        <img
          className="opacity-20 lg:hidden w-40 translate-x-1/2 translate-y-4 "
          src={ESLogo}
          alt=""
        />
        <h3 className="text-base lg:text-xl w-full mt-8 lg:mt-12">
          The EdgeSimps are a group of individuals whose experience and
          expertise spans every sector of crypto. EdgeSimps has a number of arms
          from propriatery trading using robust infrastructure to in-depth
          research in defi, gamefi, NFTs, and crypto infrastructure.
        </h3>
        <h3 className="text-sm lg:text-lg w-5/6 mt-4 lg:mt-12">
          If you're building, we want to know about it. Contact
          admin@edgesimps.com
        </h3>
      </div>
      <div
        id="right-container"
        className="w-1/2 h-full hidden lg:flex items-center justify-end"
      >
        <img className="opacity-20 " src={ESLogo} alt="" />
      </div>
    </div>
  )
}
