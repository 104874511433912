import React from "react"
import Header from "./Components/Header.js"
import Footer from "./Components/Footer.js"
import Home from "./Components/Home.js"
import Portfolio from "./Components/Portfolio.js"
import Ventures from "./Components/Ventures.js"
import Tools from "./Components/Tools.js"
import Team from "./Components/Team.js"
import Gojo from "./Components/Personel/Gojo.js"
import Milst from "./Components/Personel/Milst.js"

// Blog posts //
import Dave5_4_22 from "./Components/BlogPosts/Dave5_4_22.js"
import Jon6_6_22 from "./Components/BlogPosts/Jon6_6_22.js"

import Blog from "./Components/Blog.js"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"

function App() {
  return (
    <Router>
      <Header />
      <div className="bg-esdark w-screen h-screen flex flex-col">
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/blog">
            <Blog />
          </Route>
          <Route path="/blog/why-your-public-roadmap-for-your-jpeg-project-is-stupid">
            <Dave5_4_22 />
          </Route>
          <Route path="/blog/in-it-for-the-tech">
            <Jon6_6_22 />
          </Route>
          <Route exact path="/team">
            <Team />
          </Route>
          <Route exact path="/team/basedGojo">
            <Gojo />
          </Route>
          <Route path="/team/milst">
            <Milst />
          </Route>
          <Route path="/tools">
            <Tools />
          </Route>
          <Route path="/ventures">
            <Ventures />
          </Route>
          <Route path="/portfolio">
            <Portfolio />
          </Route>
        </Switch>
      </div>
    </Router>
  )
}

export default App
