import React from "react"
import milst from "../../Resources/PFPs/milst.jpg"
import grayarrow from "../../Resources/greenarrow.png"
import { Link } from "react-router-dom"

export default function Gojo() {
  return (
    <div className="w-screen h-screen px-6 mt-8 flex flex-col">
      <div className="flex">
        <div className="w-1/3 h-5/6 ml-6 flex flex-col items-center">
          <img src={milst} className="w-5/6 shadow-xl rounded-lg" alt="" />
          <h1 className="text-5xl cursor-pointer text-eslight mt-8 font-bold">
            Milst
          </h1>
        </div>
        <div className="ml-28 w-1/2 h-5/6 grid grid-cols-6">
          <h1 className="text-xl font-extrabold">Roles:</h1>
          <h1 className="col-span-5 text-xl">NFTs, Marketing</h1>
          <h1 className="text-xl">Bio:</h1>
          <h1 className="col-span-5 text-xl">
            Milst is an ex pro poker player and after crackdowns went to start a
            few consulting companies, where he had a small exit. He is full time
            crypto since and currently building Exchange.art, the prime fine art
            marketplace in Solana.
          </h1>
          <h1 className="text-xl">Interests:</h1>
          <h1 className="col-span-5 text-xl">
            NFTs, Game Theory, Money Markets, Fine Arts, Wine, Shitposting
          </h1>

          <h1 className="text-xl">Twitter:</h1>
          <h1 className="col-span-5 text-xl">@MilstGuga</h1>
        </div>
      </div>
      <div className="w-5/6 mt-16 flex items-center justify-end ">
        <Link to="/team">
          <div className="cursor-pointer flex">
            <img src={grayarrow} className="w-6" alt="" />
            <h1 className="text-lg ml-2 text-eslight">back to team</h1>
          </div>
        </Link>
      </div>
    </div>
  )
}
