import React from "react"
import achilles from "../Resources/PFPs/0xAchilles.jpg"
import alec from "../Resources/PFPs/alec.jpg"
import basedGojo from "../Resources/PFPs/gojo.jpg"
import dave from "../Resources/PFPs/dave.jpg"
import harvey from "../Resources/PFPs/harvey.jpg"
import salience from "../Resources/PFPs/salience.jpg"
import Hounds from "../Resources/PFPs/hounds.jpg"
import krane from "../Resources/PFPs/krane.jpg"
import milst from "../Resources/PFPs/milst.jpg"
import nosleepjon from "../Resources/PFPs/nosleepjon.jpg"
import nursegreg from "../Resources/PFPs/nursegreg.jpg"
import skunt from "../Resources/PFPs/skunt.jpg"
import riddle from "../Resources/PFPs/riddle.jpg"
import sim from "../Resources/PFPs/sim.jpg"
import ryan from "../Resources/PFPs/ryan.jpg"
import stockmart from "../Resources/PFPs/stockmart.jpg"
import Zak from "../Resources/PFPs/zak.jpg"
import slurp from "../Resources/PFPs/slurp.jpg"
import Juice from "../Resources/PFPs/0xjuice.jpg"
import { Link } from "react-router-dom"

export default function Team() {
  const imageClass = "w-28 md:w-36 lg:w-48 rounded-lg"
  const nameClass = "text-lg md:text-xl lg:text-3xl text-eslight mt-2"
  const rolesClass = "text-xs md:text-lg lg:text-xl"
  const twitterHandle = "text-xs md:text-base lg:text-md cursor-pointer"

  return (
    <div className="h-full w-screen flex flex-col bg-esdark">
      <h1 className="translate-x-3 lg:translate-x-0 text-2xl lg:text-5xl mt-4 px-12 md:px-16 font-bold">
        The EdgeSimps Team
      </h1>
      <div className="bg-esdark px-12 lg:px-16 flex-1 grid grid-cols-2 md:grid-cols-3 xl:grid-cols-5 mt-8">
        {/* Achilles */}
        <div className="flex flex-col items-center mb-8">
          <img src={achilles} className={imageClass} alt="" />

          <h1 className={nameClass}>0xAchilles</h1>

          <h1 className={rolesClass}>Trader, Defi, Infra</h1>
          <a href="https://twitter.com/0xachilles" target="_blank">
            <h1 className={twitterHandle}>@0xachilles</h1>
          </a>
        </div>
        {/* Juice */}
        <div className="flex flex-col items-center mb-8">
          <img src={Juice} className={imageClass} alt="" />{" "}
          <h1 className={nameClass}>0xJuice</h1>{" "}
          <h1 className={rolesClass}>Dev</h1>
          <a href="https://twitter.com/0xJuice" target="_blank">
            <h1 className={twitterHandle}>@0xJuice</h1>
          </a>
        </div>
        {/* Alec */}
        <div className="flex flex-col items-center mb-8">
          {" "}
          <img src={alec} className={imageClass} alt="" />{" "}
          <h1 className={nameClass}>Alechp</h1>{" "}
          <h1 className={rolesClass}>Dev</h1>
          <a href="https://twitter.com/alechp" target="_blank">
            <h1 className={twitterHandle}>@alechp</h1>
          </a>
        </div>
        {/* basedGojo */}
        <div className="flex flex-col items-center mb-8">
          {" "}
          <img src={basedGojo} className={imageClass} alt="" />{" "}
          <h1 className={nameClass}>basedGojo</h1>{" "}
          <h1 className={rolesClass}>Defi, Dev</h1>
          <a href="https://twitter.com/basedGojo" target="_blank">
            <h1 className={twitterHandle}>@basedGojo</h1>
          </a>
        </div>
        {/* dave */}
        <div className="flex flex-col items-center mb-8">
          {" "}
          <img src={dave} className={imageClass} alt="" />{" "}
          <h1 className={nameClass}>Dave Stanton</h1>{" "}
          <h1 className={rolesClass}>Infra, Gaming, Ventures</h1>
          <a href="https://twitter.com/WhosDaveStanton" target="_blank">
            <h1 className={twitterHandle}>@WhosDaveStanton</h1>
          </a>
        </div>
        {/* guleid */}
        <div className="flex flex-col items-center mb-8">
          {" "}
          <img src={riddle} className={imageClass} alt="" />{" "}
          <h1 className={nameClass}>Guleid</h1>{" "}
          <h1 className={rolesClass}>Defi, NFTs, Gaming</h1>
          <a href="https://twitter.com/riddle245" target="_blank">
            <h1 className={twitterHandle}>@riddle245</h1>
          </a>
        </div>
        {/* harvey */}
        <div className="flex flex-col items-center mb-8">
          {" "}
          <img src={harvey} className={imageClass} alt="" />{" "}
          <h1 className={nameClass}>HarveyMilk</h1>{" "}
          <h1 className={rolesClass}>Defi, NFTs, Gaming</h1>
          <a href="https://twitter.com/imnarrating" target="_blank">
            <h1 className={twitterHandle}>@imnarrating</h1>
          </a>
        </div>
        {/* hounds */}
        <div className="flex flex-col items-center mb-8">
          {" "}
          <img src={Hounds} className={imageClass} alt="" />{" "}
          <h1 className={nameClass}>Hounds</h1>{" "}
          <h1 className={rolesClass}>Trader, Marketing</h1>
          <a href="https://twitter.com/___hounds" target="_blank">
            <h1 className={twitterHandle}>@___hounds</h1>
          </a>
        </div>
        {/* krane */}
        <div className="flex flex-col items-center mb-8">
          <img src={krane} className={imageClass} alt="" />

          <h1 className={nameClass}>Krane</h1>

          <h1 className={rolesClass}>Quant, Dev, Defi</h1>
          <a href="https://twitter.com/0xkrane" target="_blank">
            <h1 className={twitterHandle}>@0xkrane</h1>
          </a>
        </div>
        {/* milst */}
        <div className="flex flex-col items-center mb-8">
          <img src={milst} className={imageClass} alt="" />
          <h1 className={nameClass}>Milst</h1>
          <h1 className={rolesClass}>NFTs, Marketing</h1>
          <a href="https://twitter.com/MilstGuga" target="_blank">
            <h1 className={twitterHandle}>@MilstGuga</h1>
          </a>
        </div>
        {/* nosleepjon */}
        <div className="flex flex-col items-center mb-8">
          <img src={nosleepjon} className={imageClass} alt="" />
          <h1 className={nameClass}>nosleepjon</h1>
          <h1 className={rolesClass}>Defi, Ventures</h1>
          <a href="https://twitter.com/MilstGuga" target="_blank">
            <h1 className={twitterHandle}>@nosleepjon</h1>
          </a>
        </div>
        {/* Skunt */}
        <div className="flex flex-col items-center mb-8">
          <img src={skunt} className={imageClass} alt="" />
          <h1 className={nameClass}>OSkunt</h1>
          <h1 className={rolesClass}>Trader, Defi, Infra</h1>
          <a href="https://twitter.com/Hol3Skunt" target="_blank">
            <h1 className={twitterHandle}>@Hol3Skunt</h1>
          </a>
        </div>{" "}
        {/* ryan */}
        <div className="flex flex-col items-center mb-8">
          <img src={ryan} className={imageClass} alt="" />
          <h1 className={nameClass}>Ryan Jacob</h1>
          <h1 className={rolesClass}>Defi, NFTs, Gaming</h1>
          <a href="https://twitter.com/ryanjacobxx" target="_blank">
            <h1 className={twitterHandle}>@ryanjacobxx</h1>
          </a>
        </div>
        {/* Salience */}
        <div className="flex flex-col items-center mb-8">
          <img src={salience} className={imageClass} alt="" />

          <h1 className={nameClass}>Salience</h1>
          <h1 className={rolesClass}>Quant, Trader</h1>
          <a href="https://twitter.com/salience_xbt" target="_blank">
            <h1 className={twitterHandle}>@salienceXBT</h1>
          </a>
        </div>{" "}
        {/* Slurp */}
        <div className="flex flex-col items-center mb-8">
          <img src={slurp} className={imageClass} alt="" />
          <h1 className={nameClass}>Slurp</h1>
          <h1 className={rolesClass}>Quant, Trader, Dev</h1>
          <a href="https://twitter.com/slurpxbt" target="_blank">
            <h1 className={twitterHandle}>@slurpxbt</h1>
          </a>
        </div>{" "}
        {/* stock */}
        <div className="flex flex-col items-center mb-8">
          <img src={stockmart} className={imageClass} alt="" />
          <h1 className={nameClass}>Stockmart</h1>
          <h1 className={rolesClass}>Trader, Defi, Marketing</h1>
          <a href="https://twitter.com/stockmart_" target="_blank">
            <h1 className={twitterHandle}>@stockmart</h1>
          </a>
        </div>{" "}
        {/* sim */}
        <div className="flex flex-col items-center mb-8">
          <img src={sim} className={imageClass} alt="" />
          <h1 className={nameClass}>SimpelAlpha</h1>
          <h1 className={rolesClass}>Quant, Trader</h1>
          <a href="https://twitter.com/SimpelAlpha" target="_blank">
            <h1 className={twitterHandle}>@SimpelAlpha</h1>
          </a>
        </div>{" "}
        {/* greg */}
        <div className="flex flex-col items-center mb-8">
          <img src={nursegreg} className={imageClass} alt="" />
          <h1 className={nameClass}>Thenursegreg</h1>
          <h1 className={rolesClass}>Defi, Media</h1>
          <a href="https://twitter.com/TheNurseGreg" target="_blank">
            <h1 className={twitterHandle}>@TheNurseGreg</h1>
          </a>
        </div>
        {/* zak */}
        <div className="flex flex-col items-center mb-8">
          <img src={Zak} className={imageClass} alt="" />
          <h1 className={nameClass}>Zak</h1>
          <h1 className={rolesClass}>Trader, NFT, Socials</h1>
          <a href="https://twitter.com/_zak_____" target="_blank">
            <h1 className={twitterHandle}>@_zak_____</h1>
          </a>
        </div>
      </div>
    </div>
  )
}
