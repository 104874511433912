import React from "react"
import basedGojo from "../../Resources/PFPs/gojo.jpg"
import grayarrow from "../../Resources/greenarrow.png"
import { Link } from "react-router-dom"

export default function Gojo() {
  return (
    <div className="w-screen h-screen px-6 mt-8 flex flex-col">
      <div className="flex">
        <div className="w-1/3 h-5/6 ml-6 flex flex-col items-center">
          <img src={basedGojo} className="w-5/6 shadow-xl rounded-lg" alt="" />
          <h1 className="text-5xl cursor-pointer text-eslight mt-8 font-bold">
            basedGojo
          </h1>
        </div>
        <div className="ml-28 w-1/2 h-5/6 grid grid-cols-6">
          <h1 className="text-xl font-extrabold">Roles:</h1>
          <h1 className="col-span-5 text-xl">Dev, Defi</h1>
          <h1 className="text-xl">Bio:</h1>
          <h1 className="col-span-5 text-xl">
            Gojo is a self taught full-stack crypto dev (anti-web3 gang). He
            first entered the space in 2016 and full-sent it in 2020. In his
            free time, he is a medical student who researches in the field of
            radiomics - an upcoming field utilizing machine learning for medical
            imaging data extraction.
          </h1>
          <h1 className="text-xl">Interests:</h1>
          <h1 className="col-span-5 text-xl">
            Machine learning, UI/UX web development, money markets, medicine,
            radiomics, differential equations
          </h1>

          <h1 className="text-xl">Twitter:</h1>
          <h1 className="col-span-5 text-xl">@basedGojo</h1>
        </div>
      </div>
      <div className="w-5/6 mt-16 flex items-center justify-end ">
        <Link to="/team">
          <div className="cursor-pointer flex">
            <img src={grayarrow} className="w-6" alt="" />
            <h1 className="text-lg ml-2 text-eslight">back to team</h1>
          </div>
        </Link>
      </div>
    </div>
  )
}
